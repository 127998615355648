import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { PathRoutePrincipal } from "../utils/helpers";

const PrivateRoute = ({
    isAutenticated,
    component: Component,
    ...rest
}) => {
    return (
        <Route 
            { ...rest }
            component={ (props) => (
                (isAutenticated) ? (<Component { ...props }/>)
                : (<Redirect to={`/${PathRoutePrincipal}/login`} />)
            )}
        />
    );
}

PrivateRoute.propTypes = {
    isAutenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
};

export default PrivateRoute;